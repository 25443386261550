<template>
  <div>
    <v-icon
      v-if="this.user.role_name == 'AGENT-R' ||this.user.role_name == 'SUB-AGENT-R' "
      color="green"
      small
      class=""
      @click="getUserChild5"
      >{{reportUrl ? "mdi-eye" :"mdi-eye-off"}} 
    </v-icon>
    <v-icon
      v-if="this.user.role_name !== 'AGENT-R' && this.user.role_name !== 'SUB-AGENT-R'"
      color="green"
      small
      class=""
      @click="getUserChild1"
      >{{reportUrl ? "mdi-eye" :"mdi-eye-off"}} 
    </v-icon>
    <statement-list1
      v-if="user.role_name !='AGENT-R' && user.role_name !='SUB-AGENT-R'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild"
      :user="user"
      :p_com_id="item._id._id"
      :totalExceptSenior1="totalExceptSenior1" :totalExceptSenior2="totalExceptSenior2"
      :data-request="data1"
      v-on:getData1="getUserChild1"
      @onCloseDialog="dialogListUser = !dialogListUser"
      :date="date"
    />
    <statement-list5
      v-if="user.role_name =='AGENT-R' || user.role_name =='SUB-AGENT-R'"
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild5"
      :data-request="data5"
      v-on:getData5="getUserChild5"
      :date="date"
      :user="user"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import StatementList1 from "../statementList/Dialog1";
import StatementList5 from "../statementList/Dialog5";
import moment from "moment";
import Vue from "vue";

export default {
  components: { StatementList1, StatementList5 },
  props: ["darkmode", "item", "params", "dataDelete", "userPermission","user", "date"],
  data() {
    return {
      validPassword: true,
      dialogListUser: false,
      loading: false,
      userChild: [],
      userChild5:[],
      data: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["amount_win"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id.user_name",
          "_id.balance",
          "amount",
          "amount_win",
          "_id.app_id",
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
        exceptComId: null,
        exceptSeniorId: [],
      },
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["_id.user_name", "_id.balance", "amount", "amount_win"],
        search: "",
        startDate: new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
     data5: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id._id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["co_type_of_betting.name", "co_type_of_betting.desc", "co_type_of_betting.color", "date", "fight_no", "is_win", "amount", "amount_win", "cast"],
        search: "",
        startDate:  new Date().toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
      },
      totalExceptSenior1: 0,
      totalExceptSenior2: 0,
    };
  },
  watch: {
    user: function() {
      // Emit this information to the parents component
    } 
  },
  computed: {
   reportUrl: function(){
     if(this.user){
        if(this.user.role_name == "DEVELOPER" || this.user.role_name == "MANAGER" || this.user.role_name == "SPECIAL-MANAGER") return "senior"
        else if(this.user.role_name == "COMPANY" || this.user.role_name == "SUB-COMPANY") return "master"
        else if(this.user.role_name == "SENIOR-R" || this.user.role_name == "SUB-SENIOR-R") return "agent"
        else if(this.user.role_name == "MASTER-R" || this.user.role_name == "SUB-MASTER-R") return "player"
        else if(this.user.role_name == "AGENT-R" || this.user.role_name == "SUB-AGENT-R") return "playerDetail"
        else return "Loading..."
     }
     else return "Loading..."
  }
 },
  methods: {
    async getUserChild1(v_search="") {
      if( v_search!= undefined){
         if(v_search.type && v_search.type == "click") v_search = ""
         else if(v_search != "") this.data1.search = v_search
         else this.data1.search = ""
      }
     else this.data1.search  =""
      if(this.date){
        this.data1.startDate = this.date.startDate
        this.data1.endDate = this.date.endDate
      }
      this.data1.sortByFormat.length < 1
        ? (this.data1.sortBy = "_id._id")
        : (this.data1.sortBy = this.data1.sortByFormat[0]);
      this.data1.descendingFormat.length > 0
        ? (this.data1.descending = this.data1.descendingFormat[0])
        : (this.data1.descending = true);
      Vue.$cookies.set("currency-type", 2, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data1
        })
        .then(res => {
          if (res.data.code) {
            // console.log(this.reportUrl, this.data1)
            this.dialogListUser = true;
            this.userChild = res.data.data;
          }
        });
      await this.mgCmReport1(v_search);
      await this.mgCmReport2(v_search);  
    },
    getUserChild5(search="") {
      if(search) this.data5.search = search
      else  this.data5.search = " "
      if(this.date){
        this.data5.startDate = this.date.startDate
        this.data5.endDate = this.date.endDate
      }

      this.data5.sortByFormat.length < 1
        ? (this.data5.sortBy = "_id")
        : (this.data5.sortBy = this.data5.sortByFormat[0]);
      this.data5.descendingFormat.length > 0
        ? (this.data5.descending = this.data5.descendingFormat[0])
        : (this.data5.descending = true);
      Vue.$cookies.set("currency-type", 2, 0);
      this.$request
        .post({
          url: "statementChannel/" + this.reportUrl + "/" + this.item._id._id,
          data: this.data5
        })
        .then(res => {
          if (res.data.code) {
            // console.log(this.reportUrl)
            this.dialogListUser = true;
            this.userChild5 = res.data.data;
          }
        });
    
        },
        async mgCmReport1(v_search = ""){
          let env_mg = process.env.VUE_APP_MG_ID;
          let com1_id = process.env.VUE_APP_COM_ID1;
          let env_sen1 = process.env.VUE_APP_SENIOR_ID1;
          let arr_sen1 = env_sen1? JSON.parse(env_sen1): [];
          let arr_mg = env_mg ? JSON.parse(env_mg) : [];
          if(arr_mg.includes(this.user._id)){
            this.data.exceptComId = com1_id;
            this.data.exceptSeniorId = arr_sen1;
            if (v_search != undefined) {
              if (v_search.type && v_search.type == "click") v_search = ""
              else if (v_search != "") this.data.search = v_search
              else this.data.search = ""
            }
            else this.data.search = ""
            if (this.date) {
                this.data.startDate = moment(this.date.startDate).format("YYYY-MM-DD")+"T00:00:00" ;
                this.data.endDate = moment(this.date.endDate).format("YYYY-MM-DD") + "T23:59:59";
              this.data.statusTime = this.date.statusTime;
            }
            console.log("this.data.search",this.data.search);
            this.data.sortByFormat.length < 1 ? (this.data.sortBy = "_id._id") : (this.data.sortBy = this.data.sortByFormat[0]);
            this.data.descendingFormat.length > 0 ? (this.data.descending = this.data.descendingFormat[0]) : (this.data.descending = true);
            Vue.$cookies.set("currency-type", 1, 0);
            let excData = {
              data: this.data,
            }
            let excCount = await this.fetchCountExceptCompany(excData);
            this.totalExceptSenior1 = excCount;
          }
        },
        async mgCmReport2(v_search = ""){
            let env_mg = process.env.VUE_APP_MG_ID;
            let com2_id = process.env.VUE_APP_COM_ID2;
            let env_sen2 = process.env.VUE_APP_SENIOR_ID2;
            let arr_sen2 = env_sen2? JSON.parse(env_sen2) : [];
            let arr_mg = env_mg ? JSON.parse(env_mg) : [];
              if(arr_mg.includes(this.user._id)){
              this.data.exceptComId = com2_id;
              this.data.exceptSeniorId = arr_sen2;
              if (v_search != undefined) {
                if (v_search.type && v_search.type == "click") v_search = ""
                else if (v_search != "") this.data.search = v_search
                else this.data.search = ""
              }
              else this.data.search = ""
              if (this.date) {
                  this.data.startDate = moment(this.date.startDate).format("YYYY-MM-DD")+"T00:00:00" ;
                  this.data.endDate = moment(this.date.endDate).format("YYYY-MM-DD") + "T23:59:59";
                this.data.statusTime = this.date.statusTime;
              }
              this.data.sortByFormat.length < 1 ? (this.data.sortBy = "_id._id") : (this.data.sortBy = this.data.sortByFormat[0]);
              this.data.descendingFormat.length > 0 ? (this.data.descending = this.data.descendingFormat[0]) : (this.data.descending = true);
              Vue.$cookies.set("currency-type", 1, 0);
              let excData = {
                data: this.data,
              }
              let excCount = await this.fetchCountExceptCompany(excData);
              this.totalExceptSenior2 = excCount;
            }
        },
    ...mapActions("$_statementKh", ["fetchListStatement", "fetchCountExceptCompany"])
  }
};
</script>
<style scoped>
.action-div {
  text-align: left;
  padding-left:5px
}
</style>
